<template>
  <div>
    <div class="d-flex mb-3 justify-content-center">
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded bg-primary d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          1
        </div>
        Create Order
        <feather-icon icon="ChevronRightIcon" size="35" class="mx-2" />
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded bg-primary d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          2
        </div>
        Key in Details
        <feather-icon icon="ChevronRightIcon" size="35" class="mx-2" />
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded bg-primary d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          3
        </div>
        Finalize Order
        <feather-icon icon="ChevronRightIcon" size="35" class="mx-2" />
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded bg-primary d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          4
        </div>
        Proceed Payment
      </div>
    </div>
    <b-card>
      <div class="w-100">
        <div class="d-flex">
          <div
            class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
          >
            <feather-icon icon="HomeIcon" size="35" color="white" />
          </div>
          <h3 class="text">Address</h3>
        </div>
        <div class="ml-5">
          <h4 class="ml-4">Delivery Date: {{ form.delivery_datetime }}</h4>
        </div>
        <b-row class="d-flex align-items-center justify-content-center mx-1">
          <b-col cols="5" class="border border-primary mr-5 py-3">
            <h4>{{ form.sender_first_name }} {{ form.sender_last_name }}</h4>
            <h6>
              {{ form.sender_unit_number }} {{ form.sender_address_line_1 }}
              {{ form.sender_address_line_2 }} {{ form.sender_postcode }}
              {{ form.sender_area }} {{ form.sender_state }}
              {{ form.sender_country }}
            </h6>
            <h6>Phone Number: {{ form.sender_phone_number }}</h6>
          </b-col>
          <b-col cols="5" class="border border-primary ml-5 py-3">
            <h4>
              {{ form.recipient_first_name }} {{ form.recipient_last_name }}
            </h4>
            <h6>
              {{ form.recipient_unit_number }}
              {{ form.recipient_address_line_1 }}
              {{ form.recipient_address_line_2 }} {{ form.recipient_postcode }}
              {{ form.recipient_area }} {{ form.recipient_state }}
              {{ form.recipient_country }}
            </h6>
            <h6>Phone Number: {{ form.recipient_phone_number }}</h6>
          </b-col>
        </b-row>
      </div>
      <div class="w-100 my-5">
        <div class="d-flex">
          <div
            class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
          >
            <feather-icon icon="BoxIcon" size="35" color="white" />
          </div>
          <h3 class="text">Product Details</h3>
        </div>
        <b-card class="tableBG ml-5">
          <div class="p-2 pb-5 mx-">
            <vue-good-table
              :columns="incoming.columns"
              :rows="incoming.rows"
              styleClass="vgt-table"
            >
            </vue-good-table>
          </div>
        </b-card>
      </div>
      <div>
        <div class="d-flex">
          <div
            class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
          >
            <feather-icon icon="DollarSignIcon" size="35" color="white" />
          </div>
          <h3 class="text">Total</h3>
        </div>
        <div class="d-flex align-items-center justify-content-between ml-5">
          <b-row align-h="between">
            <b-col>
              <div>
                <b-row>
                  <b-col><h4>Voucher Code</h4></b-col>
                  <b-col>
                    <select
                      class="form-control form-select rounded w-50"
                      v-model="voucher"
                      @change="voucherUpdate()"
                    >
                      <option value="">No Voucher Code</option>
                      <option
                        v-for="voucherList in voucherList"
                        :key="voucherList.id"
                        :value="voucherList"
                      >
                        {{ voucherList.voucher_code }}
                      </option>
                    </select></b-col
                  >
                </b-row>
                <b-row>
                  <b-col><h4>Unit Price</h4></b-col>
                  <b-col>
                    <span v-if="this.double_charge == 0">
                      {{ $helpers.formatTotal(this.form.unit_price) }}</span
                    >
                    <span v-if="this.double_charge != 0">
                      {{ $helpers.formatTotal(this.form.double_charge) }}</span
                    >
                  </b-col>
                </b-row>
                <b-row>
                  <b-col><h4>Sub Total</h4></b-col>
                  <b-col>{{ $helpers.formatTotal(subTotal) }}</b-col>
                </b-row>
                <b-row>
                  <b-col><h4>Voucher Discount</h4></b-col>
                  <b-col>{{
                    $helpers.formatTotal(this.voucherDiscount)
                  }}</b-col>
                </b-row>
                <b-row>
                  <b-col><h4>Tax Charge</h4></b-col>
                  <b-col>{{ $helpers.formatTotal(taxCharge) }}</b-col>
                </b-row>
                <b-row>
                  <b-col><h4>Grand Total</h4></b-col>
                  <b-col
                    ><div v-if="this.voucherDiscount == 0">
                      {{ $helpers.formatTotal(this.form.amount) }}
                    </div>
                    <div v-else>
                      <strike>{{
                        $helpers.formatTotal(this.form.amount)
                      }}</strike>
                      <span class="text-danger">
                        {{ $helpers.formatTotal(this.voucherTotal) }}</span
                      >
                    </div></b-col
                  >
                </b-row>
              </div></b-col
            >
            <b-col md="2" offset-md="4">
              <div>
                <h4>WE ACCEPT</h4>
                <img
                  :src="require('@/assets/images/logo/visa.png')"
                  class="img-fluid w-75 mr-5 pr-2"
                /></div
            ></b-col>
          </b-row>
        </div>
      </div>
    </b-card>
    <div class="d-flex justify-content-center align-items-center mt-5">
      <b-button
        @click="onBack"
        variant="light"
        v-b-modal.modal-center
        class="box-shadow-1 px-5 mr-3 w-25"
        >Back</b-button
      >
      <b-button
        @click="onSubmit"
        variant="gradient-primary"
        v-b-modal.modal-center
        class="box-shadow-1 px-5 w-25"
        >Proceed Payment</b-button
      >
    </div>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
    VueGoodTable,
  },

  data() {
    return {
      voucherList: [],
      voucher: "",
      voucherDiscount: 0,
      voucherTotal: 0,
      rangeDate: null,
      subTotal: "",
      taxCharge: "",
      form: {},
      double_charge: "",
      incoming: {
        columns: [
          {
            label: "Product Category",
            field: "product_category",
            tdClass: "text-center",
          },
          {
            label: "Packaging",
            field: "packaging",
            tdClass: "text-center",
          },
          {
            label: "QTY",
            field: "quantity",
            tdClass: "text-center",
          },
          {
            label: "Size",
            field: "item_size",
            tdClass: "text-center",
          },
          {
            label: "Weight ( kg )",
            field: "item_weight",
            tdClass: "text-center",
          },
          {
            label: "SKU",
            field: "sku",
            tdClass: "text-center",
          },
          {
            label: "Temperature",
            field: "temperature",
            tdClass: "text-center",
          },
          {
            label: "Remarks",
            field: "remarks",
            tdClass: "text-center",
          },
        ],
        rows: [{}],
      },
    };
  },
  created() {
    this.form = this.$route.params.data;
    this.double_charge = this.$route.params.double_charge;
    if (this.double_charge == 0) {
      this.subTotal = this.form.unit_price * this.form.quantity;
    } else {
      this.subTotal = this.form.double_charge * this.form.quantity;
    }
    this.taxCharge = this.subTotal * this.form.tax_percentage;
    this.incoming.rows[0].product_category = this.form.product_category;
    this.incoming.rows[0].sku = this.form.sku;
    this.incoming.rows[0].packaging = this.form.packaging;
    this.incoming.rows[0].quantity = this.form.quantity;
    this.incoming.rows[0].temperature = this.form.temperature;
    this.incoming.rows[0].remarks = this.form.remarks;
    this.incoming.rows[0].item_size = this.form.item_size;
    this.incoming.rows[0].item_weight = this.form.item_weight;
    this.$http
      .get(`assigned_voucher_per_user/${this.form.user_id.branch_id}`)
      .then((response) => {
        this.voucherList = response.data.data;
      });
    this.voucherDiscount = 0;
  },

  methods: {
    voucherUpdate() {
      let form = {};
      form.voucher_code = this.voucher.voucher_code;
      form.branch_id = this.form.user_id.branch_id;
      form.total = this.form.amount;
      form.quantity = this.form.quantity;
      this.$http.post(`check_voucher`, form).then((response) => {
        if (response.data.status) {
          this.form.voucher = response.data.data;
          this.form.voucher_discount = response.data.data.voucher_discount;
          this.voucherDiscount = response.data.data.voucher_discount;
          this.voucherTotal = this.form.amount - this.voucherDiscount;
          if (this.voucherTotal < 0) {
            this.voucherTotal = 0;
          }
        } else {
          this.voucherDiscount = 0;
          this.voucherTotal = 0;
        }
      });
    },
    onSubmit() {
      this.$router.push({
        name: "Outlet-paymentmethod",
        params: { data: this.form },
      });
    },
    onBack() {
      this.$router.push({
        name: "Outlet-finalize",
        params: {
          data: this.form,
          quotation: this.$route.params.quotation,
          double_charge: this.double_charge,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.icon {
  background-color: #0f4392;
  height: 50px;
  width: 50px;
}
.text {
  width: 15rem;
}
.notActive {
  background-color: rgba(211, 18, 100, 0.58);
}
.tableBG {
  background-color: #f7f8fc;
}
.totalText {
  margin-top: 1vh;
  width: 9vw;
}
</style>
